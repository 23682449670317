import { RecipientAvatar } from '@/domains/Business/components'
import { Typography } from '@/shared/ui'

import { Recipient } from '../../types'
import { getRecipientTitle } from '../../utils'

type Props = {
  recipient: Recipient
}

export const RecipientNicknameCell = ({ recipient }: Props) => {
  return (
    <div className="flex items-center gap-3">
      <RecipientAvatar entityType={recipient.entityType} />

      <div className="flex flex-col">
        <Typography bold>{recipient.nickname}</Typography>
        <Typography variant="body-small" className="text-neutral-gray-600">
          {getRecipientTitle(recipient)}
        </Typography>
      </div>
    </div>
  )
}
