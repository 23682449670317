import { Fragment } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { Big } from 'big.js'
import { useFieldArray, useForm } from 'react-hook-form'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import { GoBackButton } from '@/shared/components'
import {
  Button,
  Card,
  Form,
  FormField,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { ContractorDetails, RunPaymentContractor } from '../../../types'
import { PayAsYouGoContractorRow } from '../PayAsYouGoContractorRow'

import {
  PayAsYouGoContractorFormSchema,
  payAsYouGoContractorFormSchema,
} from './schema'

const PAY_AS_YOU_GO_CONTRACTOR_FORM_ID = 'pay-as-you-go-contractor-form'

type Props = {
  onBack?: () => void
  contractors: ContractorDetails[]
  onContinue: (data: RunPaymentContractor[]) => void
  payAsYouGoIdsAndAmounts: RunPaymentContractor[]
}

export const PayAsYouGoContractorStep = ({
  onBack,
  onContinue,
  contractors,
  payAsYouGoIdsAndAmounts,
}: Props) => {
  const initialContractors =
    payAsYouGoIdsAndAmounts.length > 0
      ? payAsYouGoIdsAndAmounts
      : (contractors ?? []).map((contractor) => ({
          id: contractor.id,
          amount: '',
        }))

  const form = useForm<PayAsYouGoContractorFormSchema>({
    mode: 'onChange',
    resolver: zodResolver(payAsYouGoContractorFormSchema),
    values: { contractors: initialContractors },
  })

  const { fields } = useFieldArray({
    name: 'contractors',
    control: form.control,
  })

  const selectedContractors = form.watch('contractors')

  const calculateTotalAmount = () => {
    return (
      selectedContractors?.reduce(
        (acc, contractor) =>
          acc.plus(
            contractor.amount === '' ? Big(0) : Big(contractor.amount ?? 0),
          ),
        Big(0),
      ) ?? Big(0)
    )
  }

  const handleSubmit = (data: PayAsYouGoContractorFormSchema) => {
    const validContractors = data.contractors.filter(
      (contractor) => contractor.amount !== '',
    )
    onContinue(validContractors)
  }

  const totalAmount = calculateTotalAmount()
  const contractorCount = contractors?.length ?? 0

  return (
    <>
      {onBack && <GoBackButton onClick={onBack} />}

      <SlideInScreen>
        <div className="mb-6 flex flex-col items-center gap-2">
          <Typography text="center" variant="h3">
            <FormattedMessage
              defaultMessage="Pay your pay-as-you-go contractors"
              id="contractors.runPayment.payAsYouGoContractors.title"
            />
          </Typography>

          <Typography className="text-center">
            <FormattedMessage
              id="contractors.runPayment.payAsYouGoContractors.subtitle"
              defaultMessage="Confirm the pay-as-you-go contractors you want pay"
            />
          </Typography>
        </div>

        <div className="flex flex-col">
          <div className="mb-1 flex justify-between px-6">
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="contractors.runPayment.totalContractors"
                defaultMessage="Contractors • {count} total"
                values={{ count: contractorCount }}
              />
            </Typography>

            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="contractors.runPayment.totalAmount"
                defaultMessage="Total • {amount} "
                values={{
                  amount: formatAmount({
                    amount: totalAmount.toNumber(),
                    currency: Currency.USDC,
                  }),
                }}
              />
            </Typography>
          </div>

          <Card>
            <Form {...form}>
              <form
                id={PAY_AS_YOU_GO_CONTRACTOR_FORM_ID}
                onSubmit={form.handleSubmit(handleSubmit)}
              >
                {fields.map((field, index) => (
                  <FormField
                    control={form.control}
                    key={field.id}
                    name={`contractors.${index}.amount`}
                    render={({ field: formField }) => (
                      <Fragment key={field.id}>
                        <PayAsYouGoContractorRow
                          field={formField}
                          contractor={contractors?.[index]}
                        />
                        {index !== fields.length - 1 && (
                          <div className="border-b border-neutral-gray-200" />
                        )}
                      </Fragment>
                    )}
                  />
                ))}
              </form>
            </Form>
          </Card>
        </div>

        <StickyContainer>
          <Button
            disabled={!form.formState.isValid}
            form={PAY_AS_YOU_GO_CONTRACTOR_FORM_ID}
            onClick={form.handleSubmit(handleSubmit)}
            type="submit"
            width="full"
          >
            <FormattedMessage id="action.continue" defaultMessage="Continue" />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
