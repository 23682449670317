import { useEffect, useMemo, useState } from 'react'
import { useQueries, useQuery } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { AnimatePresence } from 'framer-motion'
import { useLocation } from 'react-router'

import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { partition } from '@/lib/utils'
import { Loader, ProgressButton } from '@/shared/components'
import { FullScreen } from '@/shared/ui'

import { getContractor, getContractors } from '../api'
import {
  FixedContractorsStep,
  PayAsYouGoContractorStep,
  RunPaymentReviewStep,
} from '../components'
import {
  ContractorDetails,
  ContractorPaymentType,
  RunPaymentContractor,
} from '../types'

enum FormStep {
  FixedContractors = 'fixedContractors',
  PayAsYouGoContractors = 'payAsYouGoContractors',
  Review = 'review',
}

type FormState =
  | { type: FormStep.FixedContractors }
  | { type: FormStep.PayAsYouGoContractors }
  | { type: FormStep.Review }

export const RunPayment = () => {
  const location = useLocation()

  const contractorsQuery = useQuery({
    queryKey: [queryKeys.getContractors],
    queryFn: () => getContractors(),
    select: (data) => data?.data,
  })

  const [activeStep, setActiveStep] = useState<FormState>({
    type: FormStep.FixedContractors,
  })

  const [fixedIdsAndAmounts, setFixedIdsAndAmounts] = useState<
    RunPaymentContractor[]
  >([])

  const [payAsYouGoIdsAndAmounts, setPayAsYouGoIdsAndAmounts] = useState<
    RunPaymentContractor[]
  >([])

  const [fixedContractors, payAsYouGoContractors] = useMemo(() => {
    return partition(
      contractorsQuery.data ?? [],
      (contractor) =>
        contractor.paymentType === ContractorPaymentType.FIXED_RATE,
    )
  }, [contractorsQuery.data])

  const fixedContractorsDetails = useQueries({
    queries: fixedContractors.map((contractor) => ({
      queryKey: [queryKeys.getContractorDetails, contractor.id],
      queryFn: () => getContractor({ id: contractor.id }),
      select: (data: AxiosResponse<ContractorDetails>) => data.data,
      enabled: fixedContractors.length > 0,
    })),
  })

  const payAsYouGoContractorsDetails = useQueries({
    queries: payAsYouGoContractors.map((contractor) => ({
      queryKey: [queryKeys.getContractorDetails, contractor.id],
      queryFn: () => getContractor({ id: contractor.id }),
      select: (data: AxiosResponse<ContractorDetails>) => data.data,
      enabled: payAsYouGoContractors.length > 0,
    })),
  })

  useEffect(() => {
    if (contractorsQuery.data) {
      if (fixedContractors.length > 0) {
        setActiveStep({ type: FormStep.FixedContractors })
        return
      }

      if (payAsYouGoContractors.length > 0) {
        setActiveStep({ type: FormStep.PayAsYouGoContractors })
      }
    }
  }, [
    contractorsQuery.data,
    fixedContractors.length,
    payAsYouGoContractors.length,
  ])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [activeStep])

  const progressByStep = useMemo(() => {
    switch (activeStep?.type) {
      case FormStep.FixedContractors:
        return 25
      case FormStep.PayAsYouGoContractors:
        return 75
      case FormStep.Review:
        return 100
    }
  }, [activeStep?.type])

  if (
    fixedContractorsDetails.some((c) => c.isPending) ||
    contractorsQuery.isPending
  ) {
    return <Loader />
  }

  const fixedContractorsDetailsList = fixedContractorsDetails.map(
    (contractor) => contractor.data as ContractorDetails,
  )

  const payAsYouGoContractorsDetailsList = payAsYouGoContractorsDetails.map(
    (contractor) => contractor.data as ContractorDetails,
  )

  return (
    <FullScreen>
      <ProgressButton
        progress={progressByStep}
        to={location.state?.from ?? BusinessRoute.ContractorsOverview}
      />

      <AnimatePresence mode="wait">
        {activeStep.type === FormStep.FixedContractors ? (
          <FixedContractorsStep
            fixedIdsAndAmounts={fixedIdsAndAmounts}
            contractors={fixedContractorsDetailsList}
            onContinue={(contractors) => {
              setFixedIdsAndAmounts(contractors)

              if (payAsYouGoContractorsDetailsList.length > 0) {
                setActiveStep({ type: FormStep.PayAsYouGoContractors })
              } else {
                setActiveStep({ type: FormStep.Review })
              }
            }}
          />
        ) : null}

        {activeStep.type === FormStep.PayAsYouGoContractors ? (
          <PayAsYouGoContractorStep
            payAsYouGoIdsAndAmounts={payAsYouGoIdsAndAmounts}
            onContinue={(data) => {
              setPayAsYouGoIdsAndAmounts(data)

              setActiveStep({ type: FormStep.Review })
            }}
            onBack={
              fixedContractorsDetailsList.length > 0
                ? () => {
                    setPayAsYouGoIdsAndAmounts([])
                    setActiveStep({ type: FormStep.FixedContractors })
                  }
                : undefined
            }
            contractors={payAsYouGoContractorsDetailsList}
          />
        ) : null}

        {activeStep.type === FormStep.Review ? (
          <RunPaymentReviewStep
            onBack={() => {
              if (payAsYouGoContractorsDetailsList.length > 0) {
                setActiveStep({ type: FormStep.PayAsYouGoContractors })
              } else {
                setActiveStep({ type: FormStep.FixedContractors })
              }
            }}
            fixedIdsAndAmounts={fixedIdsAndAmounts}
            payAsYouGoIdsAndAmounts={payAsYouGoIdsAndAmounts}
          />
        ) : null}
      </AnimatePresence>
    </FullScreen>
  )
}
