import { useRef, useState } from 'react'
import { ControllerRenderProps } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import { getFullName } from '@/lib/typography'
import { getAnimationKey } from '@/lib/utils'
import {
  Button,
  FormControl,
  FormItem,
  MoneyInput,
  MotionDiv,
  Typography,
} from '@/shared/ui'

import { ContractorDetails, ContractorPaymentMethodType } from '../../types'

import { PayAsYouGoContractorFormSchema } from './RunPaymentSteps/schema'

type Props = {
  field: ControllerRenderProps<
    PayAsYouGoContractorFormSchema,
    `contractors.${number}.amount`
  >
  contractor?: ContractorDetails
}

export const PayAsYouGoContractorRow = ({ contractor, field }: Props) => {
  const intl = useIntl()
  const inputRef = useRef<HTMLInputElement>(null)
  const [isEditing, setIsEditing] = useState(field.value === '')

  return (
    <FormItem className="grid grid-cols-2">
      <div className="flex flex-col gap-1 py-3">
        <Typography bold>{getFullName(contractor?.personalDetails)}</Typography>

        <Typography className="text-neutral-gray-600">
          <FormattedMessage
            id="label.payTo"
            defaultMessage="{accountType, select, DOLAR_TAG {Pay to $DolarTag} BANK_ACCOUNT {Pay to Bank Account} other {}}"
            values={{
              accountType:
                contractor?.paymentMethod.paymentMethodDetails?.type ===
                ContractorPaymentMethodType.DOLAR_TAG
                  ? 'DOLAR_TAG'
                  : 'BANK_ACCOUNT',
            }}
          />
        </Typography>
      </div>

      <MotionDiv
        className="flex items-center text-right"
        key={getAnimationKey(isEditing)}
      >
        {isEditing ? (
          <FormControl>
            <MoneyInput
              className="py-3 text-right placeholder:text-sm placeholder:font-normal placeholder:text-neutral-gray-400"
              currency={Currency.USD}
              placeholder={intl.formatMessage({
                id: 'placeholder.enterAmount',
                defaultMessage: 'Enter amount',
              })}
              {...field}
              onBlur={() => {
                if (field.value !== '') {
                  setIsEditing(false)
                }
              }}
              onFocus={() => setIsEditing(true)}
              ref={inputRef}
            />
          </FormControl>
        ) : (
          <Button
            variant="ghost"
            className="cursor-pointer font-normal"
            size="inline"
            onClick={() => {
              setIsEditing(true)

              requestAnimationFrame(() => inputRef.current?.focus())
            }}
            asChild
          >
            <div className="flex w-full flex-col gap-1">
              <Typography className="w-full" text="right" bold>
                {formatAmount({
                  amount:
                    field.value === '' || field.value === undefined
                      ? 0
                      : field.value,
                  currency: Currency.USD,
                })}
              </Typography>
              <Typography text="right" className="w-full text-neutral-gray-600">
                {formatAmount({
                  amount:
                    field.value === '' || field.value === undefined
                      ? 0
                      : field.value,
                  currency: Currency.USDC,
                })}
              </Typography>
            </div>
          </Button>
        )}
      </MotionDiv>
    </FormItem>
  )
}
