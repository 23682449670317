import { useMemo } from 'react'
import { zodResolver } from '@hookform/resolvers/zod'
import { useQueries } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { Big } from 'big.js'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { useLocation } from 'react-router'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { BusinessRoute } from '@/constants/paths'
import { queryKeys } from '@/constants/queryKeys'
import { getRatesWithFees, RatesWithFeesResponse } from '@/domains/Business/api'
import { AccountSelect } from '@/domains/Business/components'
import { PaymentMethod, SPEI } from '@/domains/Business/constants'
import { getAccount } from '@/domains/Business/features/Accounts/api'
import { Account } from '@/domains/Business/features/Accounts/types'
import { getSingleRecipient } from '@/domains/Business/features/Recipients/api'
import { Recipient } from '@/domains/Business/features/Recipients/types'
import { useSearchParamsValue } from '@/hooks/useSearchParamsValue'
import { formatCurrency, formatRate } from '@/lib/money'
import { getRecipientAccountNumber } from '@/lib/recipient'
import { maskValue } from '@/lib/typography'
import {
  AmountInput,
  CountryWithFlag,
  CurrencyFlag,
  GoBackButton,
  OptionalTag,
  Widget,
} from '@/shared/components'
import { ArrowDown, ArrowTrendUp } from '@/shared/icons/outline'
import {
  AnimatedFormLabel,
  Button,
  Card,
  Form,
  FormControl,
  FormField,
  FormItem,
  Input,
  MoneyInput,
  MotionDiv,
  Skeleton,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'
import { CountryCode } from '@/types/country'

const SEND_FORM_ID = 'send-form-id'

const sendAmountSchema = z
  .object({
    isUSRecipient: z.boolean().optional(),
    fromBalance: z.string(),
    requestId: z.string(),
    walletId: z.string().min(1),
    amountIn: z.string(),
    amountOut: z.string(),
    paymentMessage: z.string(),
    currencyIn: z.nativeEnum(Currency),
    currencyOut: z.nativeEnum(Currency),
    selection: z.enum(['in', 'out']),
  })
  .refine(
    (data) => {
      return data.walletId
        ? Big(data.fromBalance).gte(
            data.amountIn === '' ? 0 : Big(data.amountIn),
          )
        : true
    },
    { message: 'validation.balance.insufficient', path: ['amountIn'] },
  )
  .refine(
    (data) => {
      switch (data.currencyOut) {
        case Currency.MXN: {
          if (data.amountOut === '') {
            return false
          }

          if (data.isUSRecipient) {
            return true
          }

          return Big(data.amountOut).gte(50)
        }

        default:
          return true
      }
    },
    { message: 'validation.balance.MXN.min', path: ['amountOut'] },
  )
  .refine(
    (data) => {
      if (data.isUSRecipient) {
        if (data.amountIn === '') {
          return false
        }

        return Big(data.amountIn).gte(1)
      }

      return true
    },
    { message: 'validation.balance.USDC.min', path: ['amountIn'] },
  )

export type SendAmountSchema = z.infer<typeof sendAmountSchema>

type Props = {
  sendData?: SendAmountSchema
  recipientId?: string | null
  onBack: () => void
  onContinue: (data: SendAmountSchema) => void
}

export const AmountScreen = ({
  onBack,
  onContinue,
  recipientId,
  sendData,
}: Props) => {
  const intl = useIntl()
  const [from, recipientParam] = useSearchParamsValue(['from', 'recipient'])
  const location = useLocation()

  const [accountQuery, recipientQuery, ratesResponse] = useQueries({
    queries: [
      {
        queryKey: [queryKeys.getAccount],
        queryFn: getAccount,
        select: (data: AxiosResponse<Account>) => data?.data,
      },
      {
        queryKey: [queryKeys.getSingleRecipient, recipientId],
        queryFn: () => getSingleRecipient({ id: recipientId ?? '' }),
        select: (data: AxiosResponse<Recipient>) => data.data,
        enabled: !!recipientId,
      },
      {
        queryKey: [queryKeys.getFXRates, Currency.MXN],
        queryFn: () =>
          getRatesWithFees({
            from: Currency.USDC,
            to: Currency.MXN,
          }),
        select: (data: AxiosResponse<RatesWithFeesResponse>) => data.data,
        refetchInterval: 10 * 1000,
      },
    ],
  })

  const isUSRecipient = useMemo(
    () => recipientQuery.data?.country === CountryCode.US,
    [recipientQuery.data?.country],
  )

  const walletBySearchParams = useMemo(
    () => accountQuery.data?.wallets.find((wallet) => wallet.id === from),
    [accountQuery.data?.wallets, from],
  )

  const form = useForm<SendAmountSchema>({
    mode: 'onChange',
    resolver: zodResolver(sendAmountSchema),
    values: {
      isUSRecipient: sendData?.isUSRecipient ?? isUSRecipient,
      fromBalance: sendData?.fromBalance
        ? sendData.fromBalance
        : walletBySearchParams?.balance
          ? Big(walletBySearchParams.balance).toFixed()
          : Big(0).toFixed(),
      walletId: sendData?.walletId
        ? sendData.walletId
        : walletBySearchParams?.id
          ? walletBySearchParams.id
          : '',
      currencyIn: sendData?.currencyIn ?? Currency.USDC,
      paymentMessage: sendData?.paymentMessage ?? '',
      currencyOut: sendData?.currencyOut ?? Currency.MXN,
      amountIn: sendData?.amountIn ?? '',
      amountOut: sendData?.amountOut ?? '',
      selection: sendData?.selection ?? 'in',
      requestId: sendData?.requestId ?? '',
    },
  })

  const onSubmit: SubmitHandler<SendAmountSchema> = async (data) => {
    const { selection, ...rest } = data

    switch (selection) {
      case 'in': {
        return onContinue({
          ...rest,
          amountIn: data.amountIn,
          currencyIn: data.currencyIn,
          currencyOut: data.currencyOut,
          requestId: crypto.randomUUID(),
          selection,
        })
      }

      case 'out': {
        return onContinue({
          ...rest,
          amountIn: data.amountOut,
          currencyIn: data.currencyOut,
          currencyOut: data.currencyIn,
          requestId: crypto.randomUUID(),
          selection,
        })
      }

      default:
        return
    }
  }

  const paymentType = useMemo(() => {
    if (!recipientQuery.data) {
      return undefined
    }

    switch (recipientQuery.data.country) {
      case CountryCode.US:
        return recipientQuery.data.localInformation.paymentMethod
      case CountryCode.MX:
        return SPEI
      default:
        return undefined
    }
  }, [recipientQuery.data])

  const showPaymentMessage = useMemo(() => {
    if (!paymentType) {
      return false
    }

    return [PaymentMethod.WIRE, SPEI].includes(paymentType)
  }, [paymentType])

  return (
    <>
      {recipientParam ? (
        <GoBackButton to={location.state?.from ?? BusinessRoute.Recipients} />
      ) : (
        <GoBackButton onClick={onBack} />
      )}

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="How much do you want to send?"
            id="send.selectAmount.title"
          />
        </Typography>

        <div className="p-2" />

        {recipientQuery.data ? (
          <MotionDiv className="flex items-center justify-center gap-3">
            <CountryWithFlag country={recipientQuery.data.country} />

            <Typography>
              {maskValue(getRecipientAccountNumber(recipientQuery.data))}
            </Typography>
          </MotionDiv>
        ) : (
          <Skeleton className="mx-auto h-[22px] w-48" />
        )}

        <div className="p-6" />

        <Form {...form}>
          <form
            id={SEND_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <Widget
              variant="form"
              title={
                <FormattedMessage
                  defaultMessage="Payment details"
                  id="label.paymentDetails"
                />
              }
            >
              <div className="flex flex-col">
                <FormField
                  control={form.control}
                  name="walletId"
                  render={({ field }) => {
                    return (
                      <AccountSelect
                        value={field.value}
                        accounts={accountQuery.data?.wallets}
                        showLabel
                        label={intl.formatMessage({
                          id: 'label.payingFrom',
                          defaultMessage: 'Paying from',
                        })}
                        onChange={(v) => {
                          field.onChange(v)

                          const selected = accountQuery.data?.wallets.find(
                            (wallet) => wallet.id === v,
                          )

                          if (selected) {
                            form.setValue(
                              'fromBalance',
                              Big(selected.balance).toFixed(),
                            )

                            if (form.getValues('amountIn') !== '') {
                              form.trigger('amountIn')
                            }
                          }
                        }}
                      />
                    )
                  }}
                />

                <div className="p-2" />

                {!recipientQuery.isPending ? (
                  <MotionDiv>
                    <FormField
                      control={form.control}
                      name="amountIn"
                      render={({ field }) => (
                        <FormItem>
                          <AmountInput
                            currency={
                              isUSRecipient ? Currency.USD : Currency.USDC
                            }
                            placeholder={intl.formatMessage(
                              {
                                id: 'send.selectAmount.amountIn',
                                defaultMessage:
                                  '{isUSRecipient, select, true {Recipient gets} false {You pay} other {}}',
                              },
                              { isUSRecipient },
                            )}
                            value={field.value}
                            onChange={(value) => {
                              field.onChange(value)

                              const amountOut =
                                value !== ''
                                  ? Big(value)
                                      .times(ratesResponse.data?.fxRate ?? 0)
                                      .toFixed(2)
                                  : ''

                              form.setValue('amountOut', amountOut)
                              form.trigger('amountOut')

                              form.setValue('selection', 'in')
                            }}
                          />
                        </FormItem>
                      )}
                    />
                  </MotionDiv>
                ) : (
                  <Skeleton className="h-[54px] w-full" />
                )}

                {!isUSRecipient && !recipientQuery.isPending ? (
                  <div className="relative z-10 mx-auto -mb-5 -mt-5 flex h-14 w-14 items-center justify-center rounded-full border-[8px] border-neutral-gray-100 bg-white transition-colors ">
                    <ArrowDown className="size-5" />
                  </div>
                ) : null}

                <FormField
                  control={form.control}
                  name="amountOut"
                  render={({ field }) => (
                    <FormItem>
                      {!isUSRecipient && !recipientQuery.isPending ? (
                        <MotionDiv>
                          <Card size="input" className="flex">
                            <CurrencyFlag
                              countryCode={CountryCode.MX}
                              currency={Currency.MXN}
                            />

                            <FormControl>
                              <MoneyInput
                                currency={Currency.USD}
                                placeholder={intl.formatMessage({
                                  id: 'send.selectAmount.amountOut',
                                  defaultMessage: 'Recipient gets',
                                })}
                                className="text-right"
                                value={field.value}
                                onChange={(value) => {
                                  field.onChange(value)

                                  form.trigger('amountOut')

                                  const amountIn =
                                    value !== ''
                                      ? Big(value)
                                          .div(ratesResponse.data?.fxRate ?? 1)
                                          .toFixed(2)
                                      : ''

                                  form.setValue('amountIn', amountIn)
                                  form.trigger('amountIn')
                                  form.setValue('selection', 'out')
                                }}
                              />
                            </FormControl>

                            <AnimatedFormLabel align="end">
                              <FormattedMessage
                                id="send.selectAmount.amountOut"
                                defaultMessage="Recipient gets"
                              />
                            </AnimatedFormLabel>
                          </Card>
                        </MotionDiv>
                      ) : null}
                    </FormItem>
                  )}
                />
              </div>
            </Widget>

            {!isUSRecipient && !recipientQuery.isPending ? (
              <>
                <div className="p-2" />
                <div className="flex w-full items-center gap-1">
                  <ArrowTrendUp />
                  <div className="flex items-center gap-1">
                    <Typography>1 {formatCurrency(Currency.USDC)} =</Typography>
                    {ratesResponse.isPending ? (
                      <Skeleton className="h-6 w-24 rounded-md" />
                    ) : (
                      <Typography>
                        {formatRate(ratesResponse.data?.fxRate)}{' '}
                        {formatCurrency(Currency.MXN)}
                      </Typography>
                    )}
                  </div>
                </div>
              </>
            ) : null}

            {showPaymentMessage ? (
              <>
                <div className="p-4" />
                <Widget
                  variant="form"
                  title={
                    <FormattedMessage
                      defaultMessage="Additional information"
                      id="label.additionalInformation"
                    />
                  }
                >
                  {showPaymentMessage && (
                    <FormField
                      control={form.control}
                      name="paymentMessage"
                      render={({ field }) => (
                        <FormItem>
                          <FormControl>
                            <Input
                              placeholder={intl.formatMessage({
                                defaultMessage: 'Add a payment memo',
                                id: 'send.reviewScreen.paymentMemo',
                              })}
                              {...field}
                            />
                          </FormControl>
                          <AnimatedFormLabel>
                            <FormattedMessage
                              defaultMessage="Add a payment memo"
                              id="send.reviewScreen.paymentMemo"
                            />
                          </AnimatedFormLabel>

                          {field.value === '' && <OptionalTag />}
                        </FormItem>
                      )}
                    />
                  )}
                </Widget>
              </>
            ) : null}
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            form={SEND_FORM_ID}
            disabled={
              Object.values(form.formState.errors).length > 0 ||
              !form.formState.isValid
            }
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Save & Continue"
              id="action.saveAndContinue"
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
