import { FormattedMessage, useIntl } from 'react-intl'

import { getOnboardingAddress } from '@/lib/address'
import { formatDate } from '@/lib/date'
import { Widget } from '@/shared/components'
import { Details } from '@/shared/ui'

import { ContractorDetails, ContractorPaymentMethodType } from '../../types'

type Props = {
  contractor?: ContractorDetails
}

export const ContractorPaymentInformation = ({ contractor }: Props) => {
  const intl = useIntl()

  return (
    <Widget
      title={
        <FormattedMessage
          id="label.paymentMethodInformation"
          defaultMessage="Payment method information"
        />
      }
    >
      {(() => {
        switch (contractor?.paymentMethod.paymentMethodDetails?.type) {
          case ContractorPaymentMethodType.US_BANK_ACCOUNT:
            return (
              <>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Account number"
                      id="label.accountNumber"
                    />
                  </Details.Label>
                  <Details.Value>
                    {
                      contractor?.paymentMethod.paymentMethodDetails
                        ?.paymentMethodInformation.accountNumber
                    }
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="ABA / Routing number"
                      id="label.routingNumber"
                    />
                  </Details.Label>
                  <Details.Value>
                    {
                      contractor?.paymentMethod.paymentMethodDetails
                        ?.paymentMethodInformation.routingNumber
                    }
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Address"
                      id="label.address"
                    />
                  </Details.Label>
                  <Details.Value className="whitespace-break-spaces text-right">
                    {getOnboardingAddress(
                      contractor?.paymentMethod.paymentMethodDetails
                        .paymentMethodInformation.address,
                      intl,
                    )}
                  </Details.Value>
                </Details>
                {contractor?.paymentMethod.linkedOn ? (
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        defaultMessage="Linked on"
                        id="label.linkedOn"
                      />
                    </Details.Label>
                    <Details.Value>
                      {formatDate(
                        contractor?.paymentMethod.linkedOn,
                        'dd MMM, yyyy, HH:mm',
                      )}
                    </Details.Value>
                  </Details>
                ) : null}
              </>
            )

          case ContractorPaymentMethodType.MX_BANK_ACCOUNT:
            return (
              <>
                <Details>
                  <Details.Label>
                    <FormattedMessage defaultMessage="CLABE" id="label.clabe" />
                  </Details.Label>
                  <Details.Value>
                    {
                      contractor?.paymentMethod.paymentMethodDetails
                        ?.paymentMethodInformation.clabe
                    }
                  </Details.Value>
                </Details>
                {contractor?.paymentMethod.linkedOn ? (
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        defaultMessage="Linked on"
                        id="label.linkedOn"
                      />
                    </Details.Label>
                    <Details.Value>
                      {formatDate(
                        contractor?.paymentMethod.linkedOn,
                        'dd MMM, yyyy, HH:mm',
                      )}
                    </Details.Value>
                  </Details>
                ) : null}
              </>
            )

          case ContractorPaymentMethodType.DOLAR_TAG:
            return (
              <>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="DolarTag"
                      id="label.dolarTag"
                    />
                  </Details.Label>
                  <Details.Value className="font-semibold text-primary">
                    {'$' +
                      contractor?.paymentMethod.paymentMethodDetails
                        ?.paymentMethodInformation.dolarTag}
                  </Details.Value>
                </Details>
                {contractor?.paymentMethod.linkedOn ? (
                  <Details>
                    <Details.Label>
                      <FormattedMessage
                        defaultMessage="Linked on"
                        id="label.linkedOn"
                      />
                    </Details.Label>
                    <Details.Value>
                      {formatDate(
                        contractor?.paymentMethod.linkedOn,
                        'dd MMM, yyyy, HH:mm',
                      )}
                    </Details.Value>
                  </Details>
                ) : null}
              </>
            )
          default:
            return (
              <>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Payment method"
                      id="label.paymentMethod"
                    />
                  </Details.Label>
                  <Details.Value>-</Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Linked on"
                      id="label.linkedOn"
                    />
                  </Details.Label>
                  <Details.Value>-</Details.Value>
                </Details>
              </>
            )
        }
      })()}
    </Widget>
  )
}
