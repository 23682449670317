import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import { getFullName } from '@/lib/typography'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from '@/shared/ui'

import { ContractorDetails } from '../../types'

type Props = {
  isOpen: boolean
  onOpenChange: (open: boolean) => void
  contractor: ContractorDetails
  onDismiss: () => void
}

export const DismissDialog = ({
  isOpen,
  onOpenChange,
  contractor,
  onDismiss,
}: Props) => {
  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            <FormattedMessage
              id="contractors.runPayment.dismiss.title"
              defaultMessage="Dismiss this payment?"
            />
          </DialogTitle>
          <DialogDescription>
            <FormattedMessage
              id="contractors.runPayment.dismiss.description"
              defaultMessage="Are you sure you want to dismiss the {amount} payment for {contractor}?"
              values={{
                amount: formatAmount({
                  amount: contractor.contracts[0].paymentDetails?.amount ?? 0,
                  currency: Currency.USD,
                }),
                contractor: getFullName(contractor.personalDetails),
              }}
            />
          </DialogDescription>
        </DialogHeader>

        <div className="p-4" />

        <DialogActions>
          <Button
            width="full"
            variant="secondary"
            onClick={() => onOpenChange(false)}
          >
            <FormattedMessage id="action.cancel" defaultMessage="Cancel" />
          </Button>

          <Button width="full" onClick={onDismiss}>
            <FormattedMessage id="action.dismiss" defaultMessage="Dismiss" />
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  )
}
