import { ACH, PaymentMethod, SPEI, WIRE } from '@/domains/Business/constants'
import { Recipient } from '@/domains/Business/features/Recipients/types'
import { CountryCode } from '@/types/country'

export function getRecipientAccountNumber(recipient?: Recipient) {
  switch (recipient?.country) {
    case CountryCode.MX:
      return recipient.localInformation.clabe

    case CountryCode.US:
      return recipient.localInformation.accountNumber

    default:
      return ''
  }
}

export function getRecipientPaymentMethod(recipient?: Recipient) {
  switch (recipient?.country) {
    case CountryCode.MX:
      return SPEI

    case CountryCode.US:
      return recipient.localInformation.paymentMethod === PaymentMethod.ACH
        ? ACH
        : WIRE

    default:
      return ''
  }
}
