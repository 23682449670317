import { useMemo } from 'react'
import { ColumnDef } from '@tanstack/react-table'
import { useIntl } from 'react-intl'

import { isSmallScreen } from '@/constants/breakpoints'
import { useMediaQuery } from '@/hooks/useMediaQuery'
import { DataTableColumnHeader } from '@/shared/ui'

import { ContractorPayment } from '../../types'

import {
  ContractorsAmountCell,
  ContractorsPaymentDateCell,
  PaymentActionsCell,
  PaymentCycleCell,
} from './ContractorPaymentsTable'

export const useContractorPaymentsColumns =
  (): ColumnDef<ContractorPayment>[] => {
    const isMobile = useMediaQuery(isSmallScreen)
    const intl = useIntl()

    const mobileColumns: ColumnDef<ContractorPayment>[] = useMemo(
      () => [
        {
          accessorKey: 'startDate',
          cell: ({ row }) => <PaymentCycleCell payment={row.original} />,
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.paymentCycle',
                defaultMessage: 'Payment cycle',
              })}
            />
          ),
        },
        {
          id: 'actions',
          cell: ({ row }) => <PaymentActionsCell payment={row.original} />,
        },
      ],
      [intl],
    )

    const desktopColumns: ColumnDef<ContractorPayment>[] = useMemo(
      () => [
        {
          accessorKey: 'startDate',
          cell: ({ row }) => <PaymentCycleCell payment={row.original} />,
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.paymentCycle',
                defaultMessage: 'Payment cycle',
              })}
            />
          ),
        },
        {
          accessorKey: 'totalContractors',
          cell: ({ row }) => <ContractorsAmountCell payment={row.original} />,
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.contractors',
                defaultMessage: 'Contractors',
              })}
            />
          ),
          size: 140,
        },
        {
          accessorKey: 'paymentDate',
          cell: ({ row }) => (
            <ContractorsPaymentDateCell payment={row.original} />
          ),
          header: ({ column }) => (
            <DataTableColumnHeader
              column={column}
              title={intl.formatMessage({
                id: 'label.paymentDate',
                defaultMessage: 'Payment date',
              })}
            />
          ),
          size: 140,
        },
        {
          id: 'actions',
          cell: ({ row }) => <PaymentActionsCell payment={row.original} />,
          size: 160,
        },
      ],
      [intl],
    )

    return isMobile ? mobileColumns : desktopColumns
  }
