import { useState } from 'react'
import { FormattedMessage } from 'react-intl'

import { Currency } from '@/constants/currency'
import { formatAmount } from '@/lib/money'
import {
  Button,
  Card,
  MotionDiv,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { ContractorDetails, RunPaymentContractor } from '../../../types'
import { FixedContractorRow } from '../FixedContractorRow'

type Props = {
  contractors: ContractorDetails[]
  onContinue: (contractors: RunPaymentContractor[]) => void
  fixedIdsAndAmounts: RunPaymentContractor[]
}

export const FixedContractorsStep = ({
  contractors,
  onContinue,
  fixedIdsAndAmounts,
}: Props) => {
  const [selectedContractors, setSelectedContractors] = useState(() => {
    if (fixedIdsAndAmounts.length === 0) return contractors

    return contractors.filter((contractor) =>
      fixedIdsAndAmounts.some((fixed) => fixed.id === contractor.id),
    )
  })

  const totalAmount =
    selectedContractors?.reduce(
      (acc, contractor) =>
        acc + (contractor?.contracts[0]?.paymentDetails?.amount || 0),
      0,
    ) ?? 0

  const handleContinue = () => {
    if (!selectedContractors) return

    const mappedContractors = selectedContractors.map((contractor) => ({
      id: contractor.id,
      amount: contractor.contracts[0]?.paymentDetails?.amount?.toString() ?? '',
    }))

    onContinue(mappedContractors)
  }

  const handleRemoveContractor = (contractorId: string) => {
    setSelectedContractors((prevContractors) =>
      prevContractors?.filter((contractor) => contractor.id !== contractorId),
    )
  }

  return (
    <SlideInScreen>
      <Typography text="center" variant="h3">
        <FormattedMessage
          defaultMessage="Pay your fixed-rate contractors"
          id="contractors.runPayment.fixedContractors.title"
        />
      </Typography>

      <div className="p-2" />

      <Typography className="text-center">
        <FormattedMessage
          id="contractors.runPayment.fixedContractors.subtitle"
          defaultMessage="Confirm the fixed-rate contractors you want pay"
        />
      </Typography>

      <div className="p-6" />
      <div className="flex flex-col">
        <div className="flex justify-between px-6">
          <div className="flex items-center gap-4">
            <Typography className="text-neutral-gray-600">
              <FormattedMessage
                id="contractors.runPayment.totalContractors"
                defaultMessage="Contractors • {count} total"
                values={{ count: selectedContractors.length }}
              />
            </Typography>
          </div>
          <Typography className="text-neutral-gray-600">
            <FormattedMessage
              id="contractors.runPayment.totalAmount"
              defaultMessage="Total • {amount} "
              values={{
                amount: formatAmount({
                  amount: totalAmount,
                  currency: Currency.USDC,
                }),
              }}
            />
          </Typography>
        </div>

        <div className="p-1" />

        <Card>
          {selectedContractors?.map((contractor, index) => {
            if (!contractor) return null

            const showBorder = index !== selectedContractors.length - 1

            return (
              <MotionDiv layout key={contractor.id}>
                <FixedContractorRow
                  isLastContractor={selectedContractors.length === 1}
                  onDismiss={handleRemoveContractor}
                  contractor={contractor}
                />

                {showBorder && (
                  <div className="border-b border-neutral-gray-200" />
                )}
              </MotionDiv>
            )
          })}
        </Card>
      </div>

      <StickyContainer>
        <Button onClick={handleContinue} width="full">
          <FormattedMessage id="action.continue" defaultMessage="Continue" />
        </Button>
      </StickyContainer>
    </SlideInScreen>
  )
}
