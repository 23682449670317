import { zodResolver } from '@hookform/resolvers/zod'
import { useQuery } from '@tanstack/react-query'
import { Big } from 'big.js'
import { formatDate, isSameYear } from 'date-fns'
import { SubmitHandler, useForm } from 'react-hook-form'
import { FormattedMessage, useIntl } from 'react-intl'
import { z } from 'zod'

import { Currency } from '@/constants/currency'
import { queryKeys } from '@/constants/queryKeys'
import { AccountSelect } from '@/domains/Business/components'
import { getAccount } from '@/domains/Business/features/Accounts/api'
import { formatAmount } from '@/lib/money'
import { intersperse } from '@/lib/typography'
import { GoBackButton, Widget } from '@/shared/components'
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Button,
  Card,
  Details,
  Form,
  FormField,
  SlideInScreen,
  StickyContainer,
  Typography,
} from '@/shared/ui'

import { RunPaymentContractor } from '../../../types'

const RUN_PAYMENT_FORM_ID = 'run-payment-form'

const runPaymentSchema = z.object({
  walletId: z.string().min(1),
  fromBalance: z.string(),
})

type RunPaymentSchema = z.infer<typeof runPaymentSchema>

type Props = {
  onBack: () => void
  fixedIdsAndAmounts: RunPaymentContractor[]
  payAsYouGoIdsAndAmounts: RunPaymentContractor[]
}

export const RunPaymentReviewStep = ({
  onBack,
  fixedIdsAndAmounts,
  payAsYouGoIdsAndAmounts,
}: Props) => {
  const intl = useIntl()

  const accountQuery = useQuery({
    queryKey: [queryKeys.getAccount],
    queryFn: getAccount,
    select: (data) => data?.data.wallets,
  })

  const form = useForm<RunPaymentSchema>({
    mode: 'onChange',
    resolver: zodResolver(runPaymentSchema),
    values: {
      walletId: accountQuery.data?.[0].id ?? '',
      fromBalance: Big(accountQuery.data?.[0].balance ?? 0).toFixed(),
    },
  })

  const totalFixedAmount =
    fixedIdsAndAmounts.length > 0
      ? fixedIdsAndAmounts.reduce((acc, contractor) => {
          return acc.add(contractor.amount ?? 0)
        }, Big(0))
      : Big(0)

  const totalPayAsYouGoAmount =
    payAsYouGoIdsAndAmounts.length > 0
      ? payAsYouGoIdsAndAmounts.reduce((acc, contractor) => {
          return acc.add(contractor.amount ?? 0)
        }, Big(0))
      : Big(0)

  const totalAmount =
    totalFixedAmount.add(totalPayAsYouGoAmount).toFixed(2) ?? '0'

  const onSubmit: SubmitHandler<RunPaymentSchema> = async (data) => {
    console.log(data)
  }

  const notEnoughBalance = Big(form.watch('fromBalance')).lt(Big(totalAmount))

  return (
    <>
      <GoBackButton onClick={onBack} />

      <SlideInScreen>
        <Typography text="center" variant="h3">
          <FormattedMessage
            defaultMessage="Review your payment"
            id="contractor.runPayment.review.title"
          />
        </Typography>

        <div className="p-2" />

        <Typography className="text-center">
          <FormattedMessage
            id="contractor.runPayment.review.subtitle"
            defaultMessage="Choose account, review and confirm your payment"
          />
        </Typography>

        <div className="p-6" />

        <Form {...form}>
          <form
            id={RUN_PAYMENT_FORM_ID}
            className="w-full"
            onSubmit={form.handleSubmit(onSubmit)}
          >
            <FormField
              control={form.control}
              name="walletId"
              render={({ field }) => {
                return (
                  <AccountSelect
                    value={field.value}
                    accounts={accountQuery.data}
                    label={
                      notEnoughBalance && !accountQuery.isPending
                        ? intl.formatMessage({
                            defaultMessage: 'Insufficient balance',
                            id: 'validation.balance.insufficient',
                          })
                        : intl.formatMessage({
                            defaultMessage: 'Paying from',
                            id: 'label.payingFrom',
                          })
                    }
                    showLabel
                    hasError={notEnoughBalance && !accountQuery.isPending}
                    onChange={(v) => {
                      field.onChange(v)

                      const selected = accountQuery.data?.find(
                        (wallet) => wallet.id === v,
                      )

                      if (selected) {
                        form.setValue(
                          'fromBalance',
                          Big(selected.balance).toFixed(),
                        )

                        form.trigger()
                      }
                    }}
                  />
                )
              }}
            />

            <div className="p-3" />

            <div className="flex flex-col gap-6">
              <Widget
                variant="form"
                title={
                  <FormattedMessage
                    defaultMessage="Contractors"
                    id="label.contractors"
                  />
                }
              >
                {fixedIdsAndAmounts.length > 0 ? (
                  <Accordion type="single" collapsible>
                    <Card asChild size="upload">
                      <AccordionItem value="fixed-rate">
                        <AccordionTrigger>
                          <div className="flex flex-col">
                            <Typography>
                              <FormattedMessage
                                defaultMessage="Fixed rate"
                                id="label.fixedRate"
                              />
                            </Typography>
                            <Typography
                              className="text-neutral-gray-600"
                              variant="body-small"
                            >
                              <FormattedMessage
                                id="contractor.runPayment.totalContractors"
                                defaultMessage="{count, plural, =0 {} one {# contractor} other {# contractors}}"
                                values={{
                                  count: fixedIdsAndAmounts?.length ?? 0,
                                }}
                              />
                            </Typography>
                          </div>

                          <div className="ml-auto mr-2">
                            <Typography>
                              {formatAmount({
                                amount: totalFixedAmount.toNumber(),
                                currency: Currency.USDC,
                              })}
                            </Typography>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="flex items-center justify-between pt-4">
                            <Typography>
                              <FormattedMessage
                                defaultMessage="USD payment total"
                                id="label.usdPaymentTotal"
                              />
                            </Typography>

                            <div className="flex flex-col">
                              <Typography text="right">
                                {formatAmount({
                                  amount: totalFixedAmount.toNumber(),
                                  currency: Currency.USD,
                                })}
                              </Typography>
                              <Typography
                                text="right"
                                className="text-neutral-gray-600"
                              >
                                {formatAmount({
                                  amount: totalFixedAmount.toNumber(),
                                  currency: Currency.USDC,
                                })}
                              </Typography>
                            </div>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Card>
                  </Accordion>
                ) : null}

                {payAsYouGoIdsAndAmounts.length > 0 ? (
                  <Accordion type="single" collapsible>
                    <Card asChild size="upload">
                      <AccordionItem value="fixed-rate">
                        <AccordionTrigger>
                          <div className="flex flex-col">
                            <Typography>
                              <FormattedMessage
                                defaultMessage="Pay-as-you-go"
                                id="label.payAsYouGo"
                              />
                            </Typography>
                            <Typography
                              className="text-neutral-gray-600"
                              variant="body-small"
                            >
                              <FormattedMessage
                                id="contractor.runPayment.totalContractors"
                                defaultMessage="{count, plural, =0 {} one {# contractor} other {# contractors}}"
                                values={{
                                  count: payAsYouGoIdsAndAmounts?.length ?? 0,
                                }}
                              />
                            </Typography>
                          </div>

                          <div className="ml-auto mr-2">
                            <Typography>
                              {formatAmount({
                                amount: totalPayAsYouGoAmount.toNumber(),
                                currency: Currency.USDC,
                              })}
                            </Typography>
                          </div>
                        </AccordionTrigger>
                        <AccordionContent>
                          <div className="flex items-center justify-between pt-4">
                            <Typography>
                              <FormattedMessage
                                defaultMessage="USD payment total"
                                id="label.usdPaymentTotal"
                              />
                            </Typography>

                            <div className="flex flex-col">
                              <Typography text="right">
                                {formatAmount({
                                  amount: totalPayAsYouGoAmount.toNumber(),
                                  currency: Currency.USD,
                                })}
                              </Typography>
                              <Typography
                                text="right"
                                className="text-neutral-gray-600"
                              >
                                {formatAmount({
                                  amount: totalPayAsYouGoAmount.toNumber(),
                                  currency: Currency.USDC,
                                })}
                              </Typography>
                            </div>
                          </div>
                        </AccordionContent>
                      </AccordionItem>
                    </Card>
                  </Accordion>
                ) : null}
              </Widget>

              <Widget
                title={
                  <FormattedMessage
                    defaultMessage="Payment summary"
                    id="label.paymentSummary"
                  />
                }
              >
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Payment cycle"
                      id="label.paymentCycle"
                    />
                  </Details.Label>

                  <div className="flex items-center gap-1">
                    <Typography>
                      {intersperse(
                        [
                          formatDate(
                            '2025-01-15',
                            isSameYear('2025-01-15', '2025-01-30')
                              ? 'dd MMM.'
                              : 'dd MMM. yyyy',
                          ),
                          formatDate('2025-01-30', 'dd MMM. yyyy'),
                        ],
                        ' - ',
                      )}
                    </Typography>

                    <Typography>
                      (
                      <FormattedMessage
                        id="contractor.paymentFrequency.label"
                        defaultMessage="{paymentFrequency, select, WEEKLY {Weekly} BIWEEKLY {Biweekly} SEMIMONTHLY {Semi-monthly} MONTHLY {Monthly} other {}}"
                        values={{
                          paymentFrequency: 'MONTHLY',
                        }}
                      />
                      )
                    </Typography>
                  </div>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Total contractors"
                      id="label.totalContractors"
                    />
                  </Details.Label>

                  <Details.Value>
                    {fixedIdsAndAmounts.length + payAsYouGoIdsAndAmounts.length}
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Total payment amount"
                      id="label.totalPaymentAmount"
                    />
                  </Details.Label>

                  <Details.Value>
                    {formatAmount({
                      amount: totalAmount,
                      currency: Currency.USDC,
                    })}
                  </Details.Value>
                </Details>
                <Details>
                  <Details.Label>
                    <FormattedMessage
                      defaultMessage="Your fee"
                      id="label.yourFee"
                    />
                  </Details.Label>

                  <Typography bold className="uppercase text-primary">
                    <FormattedMessage defaultMessage="Free" id="label.free" />
                  </Typography>
                </Details>
              </Widget>
            </div>
          </form>
        </Form>

        <StickyContainer>
          <Button
            width="full"
            disabled={notEnoughBalance}
            form={RUN_PAYMENT_FORM_ID}
            onClick={form.handleSubmit(onSubmit)}
            type="submit"
          >
            <FormattedMessage
              defaultMessage="Send {amount}"
              id="contractor.runPayment.action.send"
              values={{
                amount: formatAmount({
                  amount: totalAmount,
                  currency: Currency.USDC,
                }),
              }}
            />
          </Button>
        </StickyContainer>
      </SlideInScreen>
    </>
  )
}
