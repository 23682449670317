import { CountryCode } from '@/types/country'

import {
  BusinessMXRecipient,
  BusinessUSRecipient,
  CreatedRecipient,
  EntityType,
  IndividualMXRecipient,
  IndividualUSRecipient,
  SharedRecipientFormProps,
} from '../types'

import { MXBusinessForm } from './MXBusinessForm'
import { MXIndividualForm } from './MXIndividualForm'
import { USBusinessForm } from './USBusinessForm'
import { USIndividualForm } from './USIndividualForm'

type Props = {
  entityType: EntityType
  recipient?: CreatedRecipient
  onContinue: (recipient: CreatedRecipient) => void
} & SharedRecipientFormProps

export const RecipientForm = ({
  layout = 'create',
  country,
  entityType,
  isPending,
  recipient,
  onContinue,
  onDelete,
}: Props) => {
  switch (country) {
    case CountryCode.MX: {
      switch (entityType) {
        case EntityType.INDIVIDUAL: {
          return (
            <MXIndividualForm
              isPending={isPending}
              layout={layout}
              recipient={recipient as IndividualMXRecipient}
              onDelete={onDelete}
              country={CountryCode.MX}
              onContinue={(values) => {
                const { clabe, ...rest } = values

                onContinue({
                  country: CountryCode.MX,
                  entityType: EntityType.INDIVIDUAL,
                  localInformation: { clabe },
                  ...rest,
                })
              }}
            />
          )
        }
        case EntityType.BUSINESS:
          return (
            <MXBusinessForm
              isPending={isPending}
              layout={layout}
              onDelete={onDelete}
              recipient={recipient as BusinessMXRecipient}
              country={CountryCode.MX}
              onContinue={(values) => {
                const { clabe, ...rest } = values

                onContinue({
                  country: CountryCode.MX,
                  entityType: EntityType.BUSINESS,
                  localInformation: { clabe },
                  ...rest,
                })
              }}
            />
          )

        default:
          return null
      }
    }

    case CountryCode.US: {
      switch (entityType) {
        case EntityType.INDIVIDUAL:
          return (
            <USIndividualForm
              isPending={isPending}
              layout={layout}
              onDelete={onDelete}
              recipient={recipient as IndividualUSRecipient}
              country={CountryCode.US}
              onContinue={(values) => {
                const { accountNumber, routingNumber, paymentMethod, ...rest } =
                  values

                onContinue({
                  country: CountryCode.US,
                  entityType: EntityType.INDIVIDUAL,
                  localInformation: {
                    accountNumber,
                    routingNumber,
                    paymentMethod,
                  },
                  ...rest,
                })
              }}
            />
          )
        case EntityType.BUSINESS:
          return (
            <USBusinessForm
              isPending={isPending}
              layout={layout}
              onDelete={onDelete}
              recipient={recipient as BusinessUSRecipient}
              country={CountryCode.US}
              onContinue={(values) => {
                const { accountNumber, routingNumber, paymentMethod, ...rest } =
                  values

                onContinue({
                  country: CountryCode.US,
                  entityType: EntityType.BUSINESS,
                  localInformation: {
                    accountNumber,
                    routingNumber,
                    paymentMethod,
                  },
                  ...rest,
                })
              }}
            />
          )
        default:
          return null
      }
    }

    default:
      return null
  }
}
