import { cn } from '@/lib/utils'
import { Avatar, AvatarSize, AvatarVariant } from '@/shared/ui'

import { DolarAppEntityType, EntityType } from '../features/Recipients/types'
import { getIconByEntityType } from '../features/Recipients/utils'

type Props = {
  entityType: EntityType | DolarAppEntityType
  size?: AvatarSize
  variant?: AvatarVariant
}

export const RecipientAvatar = ({ entityType, size, variant }: Props) => {
  const Icon = getIconByEntityType(entityType)

  return (
    <Avatar
      variant={variant}
      size={size}
      icon={
        <Icon
          className={cn('size-5', {
            'size-8': size === 'xl',
            'size-3': size === 'sm',
            'text-primary': variant === 'primary',
          })}
        />
      }
    />
  )
}
